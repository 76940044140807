@import "../../../../../stylesheets/sass/partials/_nce_common.scss";


.selected-zip {
    padding-top: 20px;

    button {
        text-decoration: underline;
    }
}

.cta-container {
    @include mobile-breakpoint {
        margin-top: 15px;
    }
}