@use "sass:meta";

$font-families: (
  body: (
    Plain,
    sans-serif,
  ),
  heading: (
    Martina,
    serif,
  ),
);

$font-weights: (
  light: 300,
  normal: 400,
  semibold: 500,
  bold: 700,
  black: 700,
);

// Using REMS to better support accessibility
$body-font-sizes: (
  body-1: 1.143rem,
  // 16px
    body-2: 1rem,
  // 14px
    body-3: 0.857rem,
  // 12px
);

$heading-font-sizes: (
  heading-1: 4.286rem,
  // 60px
    heading-2: 2.571rem,
  // 36px
    heading-3: 2rem,
  // 28px
    heading-4: 1.429rem,
  // 20px
    heading-1-mobile: 2rem,
  // 28px
    heading-2-mobile: 1.429rem,
  // 20px
);

$underline-thickness: 1px;
$underline-spacing: 1px;

@function if-important($important) {
  @return #{if($important, "!important", "")};
}

// Mixins for use in larger css classes or for overriding
// Example: .myClass { @include body-1-size; }
// If you are looking for stand alone classes please see the _typography.modules.scss file

// *** Standard body font site wide ***
// This should only be used in the document root file
@mixin body-global($important: false) {
  @include body;
  font-size: 14px;
}

// *** Font-families ***
// These also include styling for default letter-spacings, line-heights, etc.
// Body font is set globally, should only be used if overrides are needed
// Heading font is built into our H1-H6 elements, should only be used if overrides are needed
@mixin body($important: false) {
  font-family: map-get($font-families, "body") if-important($important);
  line-height: 1.3 if-important($important);
  font-weight: map-get($font-weights, "light") if-important($important);
}

// Heading font is built into our H1-H6 elements, should only be used if overrides are needed
@mixin heading($important: false) {
  font-family: map-get($font-families, "heading") if-important($important);
  line-height: 1 if-important($important);
  letter-spacing: -0.01em if-important($important);
  font-weight: map-get($font-weights, "light") if-important($important);
  font-variant-numeric: lining-nums if-important($important);
}

// Accent fonts
@mixin sub-heading($important: false) {
  font-family: map-get($font-families, "body") if-important($important);
  line-height: 1 if-important($important);
  letter-spacing: -0.02em if-important($important);
  font-weight: map-get($font-weights, "normal") if-important($important);
  text-transform: uppercase if-important($important);
  font-size: map-get($heading-font-sizes, "heading-4") if-important($important);
}

@mixin eyebrow($important: false) {
  font-family: map-get($font-families, "body") if-important($important);
  line-height: 110% if-important($important);
  letter-spacing: 0.05em if-important($important);
  font-weight: map-get($font-weights, "normal") if-important($important);
  text-transform: uppercase if-important($important);
  font-size: map-get($body-font-sizes, "body-3") if-important($important);
}

// *** Sizes ***
// These are just for sizing, font family will usually cascade.
// If overrides are needed, can be used adjacently with family mixins i.e.
// Example: .myClass { @include heading; @include heading-2-size; }
@mixin body-1-size($important: false) {
  font-size: map-get($body-font-sizes, "body-1") if-important($important);
}

@mixin body-2-size($important: false) {
  font-size: map-get($body-font-sizes, "body-2") if-important($important);
}

@mixin body-3-size($important: false) {
  font-size: map-get($body-font-sizes, "body-3") if-important($important);
}

@mixin heading-1-size($important: false) {
  font-size: map-get($heading-font-sizes, "heading-1") if-important($important);
  @include mobile-breakpoint {
    font-size: map-get($heading-font-sizes, "heading-1-mobile") if-important($important);
  }
}

@mixin heading-2-size($important: false) {
  font-size: map-get($heading-font-sizes, "heading-2") if-important($important);
  @include mobile-breakpoint {
    font-size: map-get($heading-font-sizes, "heading-1-mobile") if-important($important);
  }
}

@mixin heading-3-size($important: false) {
  font-size: map-get($heading-font-sizes, "heading-3") if-important($important);
}

@mixin heading-4-size($important: false) {
  font-size: map-get($heading-font-sizes, "heading-4") if-important($important);
}

// ** DEPRICATED mixins **
// This mapping is what new size design wanted to map to each old size
// TODO replace instances of the font-X mixins with the above new ones
@mixin font-xs($important: false) {
  @include body-3-size($important);
}

@mixin font-sm($important: false) {
  @include body-2-size($important);
}

@mixin font-md($important: false) {
  @include body-2-size($important);
}

@mixin font-lg($important: false) {
  @include body-1-size($important);
}

@mixin font-xl($important: false) {
  @include heading-4-size($important);
}

@mixin font-xxl($important: false) {
  @include heading-3-size($important);
}

// *** Weights ***
// Each font family has a built in weight already, should only be used if overrides are needed
@mixin light-weight($important: false) {
  font-weight: map-get($font-weights, "light") if-important($important);
}

@mixin normal-weight($important: false) {
  font-weight: map-get($font-weights, "normal") if-important($important);
}

@mixin semibold($important: false) {
  font-weight: map-get($font-weights, "semibold") if-important($important);
}

@mixin bold($important: false) {
  font-weight: map-get($font-weights, "bold") if-important($important);
}

@mixin black($important: false) {
  font-weight: map-get($font-weights, "black") if-important($important);
}

// Standardized common styling
// TODO check with design if this is standard legal anymore
@mixin legal($important: false) {
  font-size: map-get($body-font-sizes, "body-3") if-important($important);
  font-style: italic if-important($important);
  color: $color-info-grey if-important($important);
}
